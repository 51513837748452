import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Data as referralData } from '../interfaces/Referral';
import { sendReferralCode } from '../http';

interface ReferralState {
    referralData: any | null
    loading: boolean
    errors: any    
  }
  
const initialState: ReferralState = {
    referralData: null,
    loading: false,
    errors: null
}

export const postReferralCode = createAsyncThunk(
    'referral/sendReferralCode',
    async ({data}: {data: any}, thunkAPI) => {
      try {
        const {token, body} = data
        const response = await sendReferralCode(token, 'referral', body)
        return response.data
      } catch (error) {
        return thunkAPI.rejectWithValue(error)
      }
    }
  )

  export const referralSlice = createSlice({
    name: 'referral',
    initialState,
    reducers: {
      setReferral: (state, action: PayloadAction<referralData>) => {
        state.referralData = action.payload
      },
    },
    extraReducers: (builder) => {
      builder.addCase(postReferralCode.pending, (state, action) => {
        state.loading = true
        state.referralData = null
        state.errors = null
      })
      builder.addCase(postReferralCode.fulfilled, (state, action) => {
        state.loading = false
        state.errors = null
        state.referralData = action.payload      
      })
      builder.addCase(postReferralCode.rejected, (state, action) => {
        state.loading = false
        state.referralData = null
        state.errors = action.payload
      })      
    },
  })

  export default referralSlice.reducer
  export const { setReferral } = referralSlice.actions